import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/post-layout.js";
import { HashLink } from '../components/link';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`ARIA live regions are a feature of the Accessible Rich Internet Applications (ARIA) specification. The ARIA spec is a set of attributes that can be added to HTML elements to make web pages more accessible for users with disabilities.`}</p>
    <p>{`ARIA live regions are used to provide real-time updates for dynamic content such as live sports scores, chat messaging, stock prices, notifications, etc.`}</p>
    <h2><HashLink id="attributes" to="/aria-live-regions#attributes" mdxType="HashLink">{`Relevant HTML attributes`}</HashLink></h2>
    <p>{`When it comes to live regions there are a few relevant HTML attributes.`}</p>
    <h3><HashLink id="aria-live" to="/aria-live-regions#aria-live" mdxType="HashLink"><inlineCode parentName="h3">{`aria-live`}</inlineCode></HashLink></h3>
    <p>{`The `}<inlineCode parentName="p">{`aria-live`}</inlineCode>{` attribute is used to specify that an element is a live region. It can be added to any HTML element, but it is typically added to a container element that contains the dynamic content that needs to be updated.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`aria-live`}</inlineCode>{` attribute accepts one of three values:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`off`}</inlineCode>{`: Indicates that updates to the live region will not be announced to the user (this is the default value).`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`polite`}</inlineCode>{`: Indicates that updates to the live region will be announced to the user at the next available opportunity, without interrupting the user's current task. This is the most common value to use.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`assertive`}</inlineCode>{`: Indicates that updates to the live region will be announced to the user immediately, interrupting whatever the user is currently doing. This value should be used sparingly as it can be disruptive.`}</li>
    </ul>
    <h3><HashLink id="aria-relevant" to="/aria-live-regions#aria-relevant" mdxType="HashLink"><inlineCode parentName="h3">{`aria-relevant`}</inlineCode></HashLink></h3>
    <p>{`The `}<inlineCode parentName="p">{`aria-relevant`}</inlineCode>{` attribute is used in conjunction with the `}<inlineCode parentName="p">{`aria-live`}</inlineCode>{` attribute to indicate `}<strong parentName="p">{`which updates`}</strong>{` to a live region should be announced to the user. In other words, the `}<inlineCode parentName="p">{`aria-relevant`}</inlineCode>{` value dictates which types of changes to the DOM trigger an announcement.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`aria-relevant`}</inlineCode>{` attribute is used to specify a space-separated list of one or more of the following values:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`all`}</inlineCode>{`: Indicates that all updates to the live region should be announced. Shorthand for `}<inlineCode parentName="li">{`additions removals text`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`additions`}</inlineCode>{`: Indicates that updates that involve the addition of content to the live region should be announced.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`removals`}</inlineCode>{`: Indicates that updates that involve the removal of content from the live region should be announced.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`text`}</inlineCode>{`: Indicates that updates that involve changes to the text content of the live region should be announced.`}</li>
    </ul>
    <p>{`The default value for `}<inlineCode parentName="p">{`aria-relevant`}</inlineCode>{` is `}<inlineCode parentName="p">{`additions text`}</inlineCode>{`. Changes are announced when element nodes are added to the accessibility tree within the live region `}<strong parentName="p">{`and`}</strong>{` text content or a text alternative is added to any descendant in the accessibility tree of the live region.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div aria-live="polite" aria-relevant="additions">
  <ul id="live-updates">
    <li>Initial content</li>
  </ul>
</div>
`}</code></pre>
    <p>{`In the example above, updates to the content inside the `}<inlineCode parentName="p">{`<ul>`}</inlineCode>{` element that involve the addition of content will be announced to the user at the next available opportunity, without interrupting the user's current task.`}</p>
    <h3><HashLink id="aria-atomic" to="/aria-live-regions#aria-atomic" mdxType="HashLink"><inlineCode parentName="h3">{`aria-atomic`}</inlineCode></HashLink></h3>
    <p>{`The `}<inlineCode parentName="p">{`aria-atomic`}</inlineCode>{` attribute is used in conjunction with the `}<inlineCode parentName="p">{`aria-live`}</inlineCode>{` attribute to indicate `}<strong parentName="p">{`how updates`}</strong>{` to a live region should be announced to the user.`}</p>
    <p>{`By default, the `}<inlineCode parentName="p">{`aria-atomic`}</inlineCode>{` attribute is set to `}<inlineCode parentName="p">{`false`}</inlineCode>{` and only the changed parts of the live region are announced. This can be useful when the live region contains a large amount of content that is frequently updated, and it's not necessary for the user to be aware of all the updates at once.`}</p>
    <p>{`When the `}<inlineCode parentName="p">{`aria-atomic`}</inlineCode>{` attribute is set to `}<inlineCode parentName="p">{`true`}</inlineCode>{`, the entire live region will be announced every time it is updated. This can be useful when the live region contains a small amount of content that is frequently updated, and it's important for the user to be aware of all the updates at once.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<!-- 
If there is a new notification, "Static content" will be announced along with the notification. 
-->
<div aria-live="polite" aria-atomic="true">
  <p>Static content</p>
  <div id="notification"></div>
</div>

<!-- 
If there is a new notification, only the new notification will be announced. 
-->
<div aria-live="polite">
  <p>Static content</p>
  <div id="notification"></div>
</div>
`}</code></pre>
    <p>{`Even though the default value is `}<inlineCode parentName="p">{`false`}</inlineCode>{` we should still include `}<inlineCode parentName="p">{`aria-atomic="false"`}</inlineCode>{` rather than omitting it.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<!-- Good -->
<div aria-live="polite" aria-atomic="false">
  <p>Static content</p>
  <div id="notification"></div>
</div>

<!-- Slightly less good -->
<div aria-live="polite">
  <p>Static content</p>
  <div id="notification"></div>
</div>
`}</code></pre>
    <p>{`Including the attribute gives the user performance benefits. From `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-atomic"
      }}>{`the MDN documentation on `}<inlineCode parentName="a">{`aria-atomic`}</inlineCode></a>{`:`}</p>
    <blockquote>
      <p parentName="blockquote">{`When the content of a live region changes, the DOM is traversed from the changed element through its ancestors to find the first element with `}<inlineCode parentName="p">{`aria-atomic`}</inlineCode>{` set. This determines the content that the user should be presented with.`}</p>
      <p parentName="blockquote">{`If no ancestor has explicitly set `}<inlineCode parentName="p">{`aria-atomic`}</inlineCode>{`, only the node or nodes of live region content that have been updated are read. The difference between omitting `}<inlineCode parentName="p">{`aria-atomic`}</inlineCode>{` altogether and explicitly setting an ARIA live region's ancestor node with `}<inlineCode parentName="p">{`aria-atomic="false"`}</inlineCode>{` is that explicitly setting `}<inlineCode parentName="p">{`aria-atomic="false"`}</inlineCode>{` will stop the screen reader from going up the ancestor chain. Both lead to only the updated node being read. When set to `}<inlineCode parentName="p">{`aria-atomic="true"`}</inlineCode>{`, the entire changed region as a whole will be presented.`}</p>
    </blockquote>
    <h3><HashLink id="aria-busy" to="/aria-live-regions#aria-busy" mdxType="HashLink"><inlineCode parentName="h3">{`aria-busy`}</inlineCode></HashLink></h3>
    <p>{`The `}<inlineCode parentName="p">{`aria-busy`}</inlineCode>{` attribute is used to indicate an element is being modified and that assistive technologies may want to wait until the changes are complete before informing the user about the update.`}</p>
    <p>{`When multiple parts of a live region need to be loaded before changes are announced to the user, set `}<inlineCode parentName="p">{`aria-busy="true"`}</inlineCode>{` until loading is complete. Then set to `}<inlineCode parentName="p">{`aria-busy="false"`}</inlineCode>{`. This prevents assistive technologies from announcing changes before updates are done.`}</p>
    <h2><HashLink id="examples" to="/aria-live-regions#examples" mdxType="HashLink">{`Practical examples`}</HashLink></h2>
    <p>{`It's important to note that while the `}<inlineCode parentName="p">{`aria-live`}</inlineCode>{` attribute is used to indicate that an element is a live region, it alone is not enough to make the dynamic content accessible. The updates to the content must also be properly structured and labeled in order for assistive technology to be able to announce them to the user.`}</p>
    <p>{`Let's take a look at some contrived but practical examples.`}</p>
    <h3><HashLink id="notifications" to="/aria-live-regions#notifications" mdxType="HashLink">{`Notifications`}</HashLink></h3>
    <p>{`Here is an example of how a live region could be used to provide notifications:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div aria-live="polite" id="notification-container">
  <div id="notification"></div>
</div>

<script>
  const showNotification = message => {
    const notification = document.getElementById('notification');

    notification.innerHTML = message;

    setTimeout(() => {
      notification.innerHTML = '';
    }, 5000);
  };
</script>
`}</code></pre>
    <p>{`The time that the message is displayed should be enough for the user to read and understand it but not too long that it becomes annoying or irrelevant.`}</p>
    <p>{`Let's see this concept in action with a more practical example.`}</p>
    <h3><HashLink id="form-submission" to="/aria-live-regions#form-submission" mdxType="HashLink">{`Form submission`}</HashLink></h3>
    <p>{`Here is an example of how a live region can be used to improve accessibility for a form.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<form>
  <div aria-live="polite" id="form-notification"></div>

  <label for="name">Name:</label>
  <input type="text" id="name" name="name" />

  <br />

  <label for="email">Email:</label>
  <input type="email" id="email" name="email" />

  <br />

  <button type="submit">Submit</button>
</form>

<script>
  const showNotification = message => {
    const notification = document.getElementById('form-notification');

    notification.innerHTML = message;

    setTimeout(() => {
      notification.innerHTML = '';
    }, 5000);
  };

  const submitForm = event => {
    event.preventDefault();

    const name = document.getElementById('name').value;
    const email = document.getElementById('email').value;

    if (!name) {
      showNotification('Please enter your name.');
    } else if (!email) {
      showNotification('Please enter your email.');
    } else {
      showNotification('Thank you for submitting the form!');
    }
  };

  const form = document.querySelector('form');

  form.addEventListener('submit', submitForm);
</script>
`}</code></pre>
    <p>{`Now we have an idea of how this works in response to user input. But how should we handle a situation where we need to notify the user otherwise?`}</p>
    <h3><HashLink id="news-site" to="/aria-live-regions#news-site" mdxType="HashLink">{`Real-time updates on a news website`}</HashLink></h3>
    <p>{`Here is an example where we fetch breaking news updates from a news API:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div aria-live="polite" id="breaking-news-updates">
  <h2>Breaking News</h2>
  <ul id="breaking-news-list">
    <li>No breaking news at this time.</li>
  </ul>
</div>

<script>
  setInterval(() => {
    fetch('https://newsapi.org/v2/top-headlines?country=us&apiKey=your_api_key')
      .then(response => response.json())
      .then(data => {
        const breakingNewsList = document.getElementById('breaking-news-list');

        breakingNewsList.innerHTML = '';

        data.articles.forEach(article => {
          const li = document.createElement('li');

          li.innerHTML = article.title;

          breakingNewsList.appendChild(li);
        });
      });
  }, 10000);
</script>
`}</code></pre>
    <p>{`It's best practice to provide a default, or "initial" value that will be announced when the page loads. That way, users of assistive technology will have an understanding of the context of the live region.`}</p>
    <h2><HashLink id="best-practices" to="/aria-live-regions#best-practices" mdxType="HashLink">{`Best practices`}</HashLink></h2>
    <p>{`Here are some best practices to consider when implementing ARIA live regions:`}</p>
    <ol>
      <li parentName="ol">{`Use the appropriate `}<inlineCode parentName="li">{`aria-live`}</inlineCode>{` value. Use `}<inlineCode parentName="li">{`polite`}</inlineCode>{` unless you have a specific reason to use `}<inlineCode parentName="li">{`assertive`}</inlineCode>{`. Only use `}<inlineCode parentName="li">{`off`}</inlineCode>{` if you don't want the live region to be announced to the user.`}</li>
      <li parentName="ol">{`Update the live regions only when it's necessary. You should try to avoid updating the live regions unless it's necessary. This will prevent users of assistive technology from being overwhelmed with too many updates. Use `}<inlineCode parentName="li">{`aria-atomic`}</inlineCode>{` and `}<inlineCode parentName="li">{`aria-relevant`}</inlineCode>{` to support the appropriate experience.`}</li>
      <li parentName="ol">{`Provide default or "initial" values. As mentioned earlier, it's best practice to provide a default or "initial" value that will be announced when the page loads so users of assistive technology will have an understanding of the context of the live region.`}</li>
      <li parentName="ol">{`Limit the number of live regions. It is best practice to limit the number of live regions on a page. Having too many live regions can be overwhelming for users of assistive technology.`}</li>
      <li parentName="ol">{`Use clear and concise updates. The updates that are announced to the user should be clear and concise. Avoid using jargon or technical terms that might be confusing to users of assistive technology.`}</li>
      <li parentName="ol">{`Leverage the `}<inlineCode parentName="li">{`aria-busy`}</inlineCode>{` attribute when multiple parts of a live region need to be asynchronously loaded before changes are announced.`}</li>
      <li parentName="ol">{`Test your live regions. It's important to test your live regions with assistive technology and with users who have disabilities to ensure that they are accessible and easy to use.`}</li>
    </ol>
    <p>{`By following these best practices you can ensure that your ARIA live regions are accessible and easy to use for all users, including those who rely on assistive technology.`}</p>
    <h2><HashLink id="next-steps" to="/aria-live-regions#next-steps" mdxType="HashLink">{`Next steps for developers`}</HashLink></h2>
    <p>{`As a developer, implementing ARIA live regions in your web development projects is an important step in making your application accessible to all users, including those who rely on assistive technology.`}</p>
    <p>{`Here are a few tips for getting started with ARIA live regions:`}</p>
    <ol>
      <li parentName="ol">{`Start by familiarizing yourself with the ARIA live regions specifications and best practices.`}</li>
      <li parentName="ol">{`Identify areas in your application where real-time updates, notifications, or dynamic content is present, and consider how you can use live regions to improve accessibility.`}</li>
      <li parentName="ol">{`Use the `}<inlineCode parentName="li">{`aria-live`}</inlineCode>{`, `}<inlineCode parentName="li">{`aria-atomic`}</inlineCode>{` and `}<inlineCode parentName="li">{`aria-relevant`}</inlineCode>{` attributes to indicate how updates to a live region should be announced to the user.`}</li>
      <li parentName="ol">{`Test your application with assistive technology and make sure that updates to live regions are being announced correctly.`}</li>
      <li parentName="ol">{`Remember to always provide an initial value or context for the live region so that users of assistive technology have an understanding of the context of the live region.`}</li>
    </ol>
    <p>{`By following these tips and incorporating ARIA live regions into your web development projects, you can help make your application more accessible to all users.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      